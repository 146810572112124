/// <reference path="./custom-window.d.ts" />
import {
    defaultValueCtx,
    Editor,
    editorViewCtx,
    rootCtx,
    serializerCtx,
    parserCtx,
    schemaCtx,
    editorStateOptionsCtx,
    prosePluginsCtx
} from '@milkdown/core';
import {commonmark} from '@milkdown/preset-commonmark';
import {menu, menuConfigCtx, MenuConfigItem} from '@milkdown-lab/plugin-menu';
import {Slice} from '@milkdown/prose/model';
import {EditorState} from '@milkdown/prose/state';
import {hardcoded} from "./ProplabUtils";

const menuItems: MenuConfigItem[][] = [
    [
        {
            type: 'button',
            content: 'B',
            key: 'ToggleStrong',
        },
        {
            type: 'button',
            content: 'I',
            key: 'ToggleEmphasis',
        },
        {
            type: "button",
            content: "*",
            key: "WrapInBulletList"
        },
        {
            type: "button",
            content: "1",
            key: "WrapInOrderedList"
        },
    ],
    [
        {
            type: 'select',
            text: 'H',
            options: [
                {id: 1, content: hardcoded('Rubrik 1')},
                {id: 2, content: hardcoded('Rubrik 2')},
                {id: 3, content: hardcoded('Rubrik 3')},
                {id: 4, content: hardcoded('Rubrik 4')},
                {id: 5, content: hardcoded('Rubrik 5')},
                {id: 6, content: hardcoded('Rubrik 6')},
                {id: 0, content: hardcoded('Paragraf')},
            ],
            onSelect: (id) => (!!id ? ['WrapInHeading', id] : 'TurnIntoText'),
        },
    ],
    /*{
        type: "button",
        content: "hr",
        key: "InsertHr"
    },
    */
]

window.PLTextEditorInstall = async function (id, initialValue, separator) {
    console.log('Text Editor install, initialValue=' + initialValue);

    const editorElement = document.getElementById(id + separator + 'editor') as HTMLElement;
    const submit = document.getElementById("text-picker-submit");

    const editor = await Editor.make()
        .config((ctx) => {
            ctx.set(menuConfigCtx.key, {
                attributes: {id: 'milkdown-menu', class: 'pl-markdown', 'data-menu': 'true'},
                items: menuItems,
            });
            ctx.set(rootCtx, editorElement);
            ctx.set(defaultValueCtx, initialValue);
        })
        .use(commonmark)
        .use(menu)
        .create();

    window.PLTextEditorUpdate = async function (id, newValue, separator) {
        console.log('Text Editor update, newValue=' + newValue);
        replaceAll(editor, newValue, false);
    }

    submit?.addEventListener('click', function () {
        const markdown = document.getElementById(id + separator + 'markdown') as HTMLInputElement
        markdown.value = getMarkdown(editor);
        window.htmx.trigger('#text-picker-custom', 'click')
    });
};

function getMarkdown(editor: Editor): string {
    return editor.action((ctx) => {
        const editorView = ctx.get(editorViewCtx);
        const serializer = ctx.get(serializerCtx);
        return serializer(editorView.state.doc);
    });
}

function replaceAll(editor: Editor, markdown: string, flush: boolean = false) {
    editor.action((ctx) => {
        const view = ctx.get(editorViewCtx)
        const parser = ctx.get(parserCtx)
        const doc = parser(markdown)

        if (!doc)
            return

        if (!flush) {
            const {state} = view
            return view.dispatch(state.tr.replace(0, state.doc.content.size, new Slice(doc.content, 0, 0)))
        }

        const schema = ctx.get(schemaCtx)
        const options = ctx.get(editorStateOptionsCtx)
        const plugins = ctx.get(prosePluginsCtx)

        const state = EditorState.create({
            schema,
            doc,
            plugins,
            ...options,
        })
        view.updateState(state)
    });
}

console.log('PLTextEditor has been installed');
