/// <reference path="./custom-window.d.ts" />
import {Modal} from 'bootstrap';

window.PLModalInstall = function (id, formId, saveBtnId, custom, separator) {
  const saveBtn = document.getElementById(saveBtnId);

  if (saveBtn && !custom) {
    saveBtn.onclick = function () {
      const form = document.getElementById(formId) as HTMLFormElement;
      const isValid = form.checkValidity();
      if (isValid) {
        window.htmx.trigger(`#${id}-custom`, 'click');
      }
    }
  }
}

window.PLModalClose = function (id, trigger, separator) {
  const element = document.getElementById(id) as HTMLElement;
  const modal = Modal.getInstance(element);
  if (modal) {
    modal.hide();
  }
  if (trigger && trigger !== '') {
    window.htmx.trigger(trigger, 'refresh')
  }
};

console.log('PLModal has been installed');
