/// <reference path="./custom-window.d.ts" />
import {Canvas, StaticCanvas, FabricImage, Rect, Circle, util, FabricObject} from 'fabric';
import {EraserBrush} from './EraserBrush';
import {MaskBrush} from './MaskBrush';
import {scaleObject, removeBrushes, swallow, createLoadingCircle} from './FabricUtils';

window.PLImageEditorInstall = function (id, project, first, second, isDisabled, isMasking, isErasing, separator) {
  console.log('Image Editor install, project=' + project + ', first=' + first + ', second=' + second + ', isDisabled=' + isDisabled + ', isMasking=' + isMasking + ', isErasing=' + isErasing);

  // Elements
  const container = document.getElementById(id + separator + 'container');
  const root = document.getElementById(id + separator + 'root');
  const canvas = new Canvas(id + separator + 'drawing');
  let panId = id + separator + 'zoom' + separator + 'pan';
  let brushId = id + separator + 'brush';
  let opacityId = id + separator + 'opacity';

  // Zooming and panning
  let isPanning = false;
  let isMouseDown = false;
  let zoomInitial = 0.5;
  let zoomLevel = zoomInitial;
  let moveX = 0;
  let moveY = 0;
  let previousX = 0;
  let previousY = 0;

  // Masking
  let pathArray: FabricObject[] = [];

  // Images
  let firstImage: [string, FabricImage] | undefined = undefined;
  let secondImage: [string, FabricImage] | undefined = undefined;

  // Last known values
  let lastDisabled = isDisabled;
  let lastMasking = isMasking;
  let lastErasing = isErasing;

  // Image generating
  let overlay: Rect | undefined = undefined;
  let loadingCircle: FabricObject[] | undefined = undefined;

  canvas.backgroundColor = 'white';
  canvas.renderAll();
  updateRoot(root, zoomLevel, moveX, moveY);
  setupInteractiveControls();
  setImages(first, second, isDisabled, isMasking, isErasing).then();

  window.PLImageEditorUpdate = function (id, first, second, isDisabled, isMasking, isErasing) {
    console.log('Image Editor update, first=' + first + ', second=' + second + ', isDisabled=' + isDisabled + ', isMasking=' + isMasking + ', isErasing=' + isErasing);
    lastDisabled = isDisabled;
    lastMasking = isMasking;
    lastErasing = isErasing;
    setImages(first, second, isDisabled, isMasking, isErasing).then(() => {
      toggleProgress(isDisabled);
      pathArray.forEach((v) => {
        v.set('visible', isMasking);
      });
      canvas.renderAll();
    });
  };

  window.PLImageEditorTogglePanning = function () {
    if (isPanning) {
      stopPanning(canvas, container, secondImage, brushId, panId, lastDisabled, lastMasking, lastErasing);
      isPanning = false;
    } else {
      startPanning(canvas, container, panId);
      isPanning = true;
    }
  }

  window.PLImageEditorZoom = function (delta) {
    zoomLevel *= 0.999 ** delta;
    if (zoomLevel > 20) zoomLevel = 20;
    if (zoomLevel < 0.01) zoomLevel = 0.01;
    updateRoot(root, zoomLevel, moveX, moveY);
  };

  window.PLImageEditorZoomReset = function () {
    zoomLevel = zoomInitial;
    updateRoot(root, zoomLevel, moveX, moveY);
  };

  window.PLImageEditorUndo = function () {
    if (pathArray.length > 0) {
      const obj = pathArray.pop()
      if (obj) {
        canvas.remove(obj);
      }
    }
    if (canvas.freeDrawingBrush instanceof EraserBrush) {
      canvas.freeDrawingBrush.reset()
    }
    canvas.renderAll();
  };

  window.PLImageEditorClear = function () {
    pathArray.forEach((path) => {
      canvas.remove(path);
    });
    canvas.renderAll();
    pathArray = [];
  };

  window.PLImageEditorSetMask = function () {
    const width = 1024;
    const height = 768;
    const upload = new StaticCanvas(undefined, {
      width: width,
      height: height,
      backgroundColor: 'black',
      enableRetinaScaling: false
    });

    lastDisabled = true;
    toggleProgress(lastDisabled);

    const scale: [number, number] = [width / canvas.width, height / canvas.height];

    const objects = pathArray.map(async (path) => {
      const obj = await path.clone();
      obj.set({stroke: 'white'});
      scaleObject(obj, scale);
      upload.add(obj);
    });

    Promise.all(objects)
      .then(() => {
        upload.renderAll();

        window.PLImageEditorClear();

        upload.getElement().toBlob(function (blob) {
          if (blob) {
            const formData = new FormData();
            formData.append('file', blob);
            formData.append('type', 'mask');
            formData.append('project', project);
            fetch('/image-editor-upload', {
              method: 'POST',
              body: formData
            }).then(r => {
              window.htmx.trigger('#image-editor-refresh-' + id, 'click');
            });
          }
        });
      });
  };

  window.PLImageEditorRemoveMask = function () {
    const formData = new FormData();
    formData.append('type', 'mask');
    formData.append('project', project);
    fetch('/image-editor-remove', {
      method: 'POST',
      body: formData
    }).then(() => {
      window.htmx.trigger('#image-editor-refresh-' + id, 'click');
    });
  };

  window.PLImageEditorBlend = function () {
    const width = 1024;
    const height = 768;
    const upload = new StaticCanvas(undefined, {
      width: width,
      height: height,
      backgroundColor: 'black',
      enableRetinaScaling: false
    });

    const scale: [number, number] = [width / canvas.width, height / canvas.height];

    const img1 = firstImage ?
      firstImage[1].clone()
        .then((obj) => {
          scaleObject(obj, scale);
          upload.insertAt(0, obj);
        })
      : Promise.resolve(null);

    const img2 = secondImage ?
      secondImage[1].clone()
        .then((obj) => {
          scaleObject(obj, scale);
          upload.insertAt(1, obj);
        })
      : Promise.resolve(null);

    Promise.all([img1, img2])
      .then(() => {
        upload.renderAll();

        upload.getElement().toBlob(function (blob) {
          if (blob) {
            const formData = new FormData();
            formData.append('file', blob);
            formData.append('type', 'blended');
            formData.append('project', project);
            fetch('/image-editor-upload', {
              method: 'POST',
              body: formData
            }).then(r => {
              window.htmx.trigger('#image-editor-refresh-' + id, 'click');
            });
          }
        });
      });
  };

  async function setImages(first: [string, string] | undefined, second: [string, string] | undefined, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
    await setSecondImage(second);
    await setFirstImage(first);
    setupBrushControls(canvas, container, secondImage, brushId, pathArray, isDisabled, isMasking, isErasing);
    installOpacityControls(canvas, secondImage, opacityId, isMasking, isErasing);
  }

  async function setFirstImage(image: [string, string] | undefined) {
    const [id1] = firstImage ?? []
    const [id2] = image ?? []
    if (id1 !== id2) {
      if (firstImage) {
        canvas.remove(firstImage[1]);
      }
      if (image && image[1] && image[1] !== '') {
        const img = await FabricImage.fromURL(image[1], {'crossOrigin': 'anonymous'});
        firstImage = [image[0], img];
        img.evented = false;
        img.hasControls = false;
        img.selectable = false;
        img.set('erasable', false);
        img.scaleToWidth(canvas.width);
        img.scaleToHeight(canvas.height);
        canvas.insertAt(0, img);
      } else {
        firstImage = undefined
      }
    }
  }

  async function setSecondImage(image: [string, string] | undefined) {
    const [id1] = secondImage ?? []
    const [id2] = image ?? []
    if (id1 !== id2) {
      if (secondImage) {
        canvas.remove(secondImage[1]);
      }
      if (image && image[1] && image[1] !== '') {
        const img = await FabricImage.fromURL(image[1], {'crossOrigin': 'anonymous'});
        secondImage = [image[0], img];
        img.evented = false;
        img.hasControls = false;
        img.selectable = false;
        img.set('erasable', true);
        img.scaleToWidth(canvas.width);
        img.scaleToHeight(canvas.height);
        canvas.insertAt(1, img);
      } else {
        secondImage = undefined
      }
      // Some brushes have dependency on second image
      removeBrushes(canvas);
    }
  }

  function toggleProgress(isDisabled: Boolean) {
    if (isDisabled) {
      loadingCircle = createLoadingCircle(canvas);
    } else {
      loadingCircle?.forEach(obj =>
        canvas.remove(obj)
      );
    }
    canvas.renderAll();
  }

  function setupInteractiveControls() {
    // Zooming
    container?.addEventListener('wheel', function (e) {
      if (e.ctrlKey === true || e.metaKey === true) {
        window.PLImageEditorZoom(e.deltaY);
      }
    });

    // Panning
    container?.addEventListener('keydown', function (e) {
      if (e.code === 'Space') {
        startPanning(canvas, container, panId);
        isPanning = true;
        swallow(e);
      }
    });
    container?.addEventListener('keyup', function (e) {
      if (e.code === 'Space') {
        stopPanning(canvas, container, secondImage, brushId, panId, lastDisabled, lastMasking, lastErasing);
        isPanning = false;
        swallow(e);
      }
    });
    if (container) {
      container.onmousedown = function (e) {
        if (isPanning) {
          isMouseDown = true;
          previousX = e.clientX;
          previousY = e.clientY;
          swallow(e);
        }
      };
      container.onmouseup = function () {
        isMouseDown = false;
      };
      container.onmousemove = function (e) {
        if (isPanning && isMouseDown) {
          moveX += (e.clientX - previousX) / zoomLevel;
          moveY += (e.clientY - previousY) / zoomLevel;
          previousX = e.clientX;
          previousY = e.clientY;
          updateRoot(root, zoomLevel, moveX, moveY);
          swallow(e);
        }
      };
    }
  }
};

function setupBrushControls(canvas: Canvas, container: HTMLElement | null, secondImage: [string, FabricImage] | undefined, brushId: string, pathArray: FabricObject[], isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
  // Brush path handling
  canvas.on('path:created', function (e) {
    // Path recording for undo
    pathArray.push(e.path);
    // Turn off selection for paths created during free drawing mode
    e.path.set({
      evented: false,
      hasControls: false,
      selectable: false
    });
    canvas.renderAll();
  });
  installBrushControls(canvas, container, secondImage, brushId, isDisabled, isMasking, isErasing);
}

function installBrushControls(canvas: Canvas, container: HTMLElement | null, secondImage: [string, FabricImage] | undefined, brushId: string, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
  const brush = document.getElementById(brushId);
  updateBrushControls(canvas, container, secondImage, brushId, isDisabled, isMasking, isErasing);
  brush?.addEventListener('input', function (e) {
    updateBrushControls(canvas, container, secondImage, brushId, isDisabled, isMasking, isErasing);
    swallow(e);
  });
}

function installOpacityControls(canvas: Canvas, secondImage: [string, FabricImage] | undefined, opacityId: string, isMasking: boolean, isErasing: boolean) {
  const opacity = document.getElementById(opacityId);
  updateOpacityControls(canvas, secondImage, opacityId, isMasking, isErasing);
  opacity?.addEventListener('input', function (e) {
    updateOpacityControls(canvas, secondImage, opacityId, isMasking, isErasing);
    swallow(e);
  });
}

function updateOpacityControls(canvas: Canvas, secondImage: [string, FabricImage] | undefined, opacityId: string, isMasking: boolean, isErasing: boolean) {
  const opacity = document.getElementById(opacityId) as HTMLInputElement;
  if (isErasing && secondImage) {
    secondImage[1].set({opacity: (+opacity?.value || 100) / 100});
    canvas.renderAll();
  } else if (secondImage && isMasking) {
    secondImage[1].set({opacity: 0.5});
    canvas.renderAll();
  }
}

function updateBrushControls(canvas: Canvas, container: HTMLElement | null, secondImage: [string, FabricImage] | undefined, brushId: string, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
  const brush = document.getElementById(brushId) as HTMLInputElement;
  if (isErasing && !isDisabled && secondImage && container) {
    if (!(canvas.freeDrawingBrush instanceof EraserBrush)) {
      removeBrushes(canvas);
      canvas.freeDrawingBrush = new EraserBrush(canvas, secondImage[1], container);
    }
    if (canvas.freeDrawingBrush) {
      canvas.freeDrawingBrush.width = +brush?.value || 50;
    }
    canvas.isDrawingMode = true;
  } else if (isMasking && !isDisabled && container) {
    if (!(canvas.freeDrawingBrush instanceof MaskBrush)) {
      removeBrushes(canvas);
      canvas.freeDrawingBrush = new MaskBrush(canvas, container);
    }
    canvas.freeDrawingBrush.color = "#ff0000";
    canvas.freeDrawingBrush.width = +brush?.value || 50;
    canvas.isDrawingMode = true;
  } else {
    removeBrushes(canvas);
    canvas.isDrawingMode = false;
  }
}

function startPanning(canvas: Canvas, container: HTMLElement | null, panId: string) {
  removeBrushes(canvas);
  canvas.isDrawingMode = false;
  canvas.defaultCursor = 'grab';
  const zoomPanButton = document.getElementById(panId);
  zoomPanButton?.classList.add('active');
  zoomPanButton?.setAttribute('aria-pressed', 'true');
  if (container) {
    container.style.cursor = 'grab';
  }
}

function stopPanning(canvas: Canvas, container: HTMLElement | null, secondImage: [string, FabricImage] | undefined, brushId: string, panId: string, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
  updateBrushControls(canvas, container, secondImage, brushId, isDisabled, isMasking, isErasing);
  canvas.defaultCursor = 'default';
  const zoomPanButton = document.getElementById(panId);
  zoomPanButton?.classList.remove('active');
  zoomPanButton?.setAttribute('aria-pressed', 'false');
  if (container) {
    container.style.cursor = 'default';
  }
}

function updateRoot(root: HTMLElement | null, zoomLevel: number, moveX: number, moveY: number) {
  if (root) {
    root.style.transform = 'scale(' + zoomLevel + ')' + 'translate(' + moveX + 'px,' + moveY + 'px)';
  }
}

console.log('PLImageEditor has been installed');
