/// <reference path="./custom-window.d.ts" />
import {ActiveSelection, Canvas, FabricImage, FabricObject, StaticCanvas} from 'fabric';

window.PLFloorPlanEditorInstall = function (id, project, reference, drawing, isDisabled, showReference, showDrawing, separator) {
  console.log('Floor Plan Editor install, project=' + project + ', reference=' + reference + ', drawing=' + drawing + ', isDisabled=' + isDisabled + ', showReference=' + showReference + ', showDrawing=' + showDrawing);

  const container = document.getElementById(id + separator + 'container');
  const root = document.getElementById(id + separator + 'root');
  const referenceRoot = document.getElementById(id + separator + 'reference-root');
  const drawingRoot = document.getElementById(id + separator + 'drawing-root');
  const referenceCanvas = new Canvas(id + separator + 'reference');
  const drawingCanvas = new Canvas(id + separator + 'drawing');
  const zoomPanButton = document.getElementById(id + separator + 'zoom' + separator + 'pan');

  // Zooming and panning
  let isPanning = false;
  let isMouseDown = false;
  let zoomInitial = 0.8;
  let zoomLevel = zoomInitial;
  let moveX = 0;
  let moveY = 0;
  let previousX = 0;
  let previousY = 0;
  let copiedObject: FabricObject | undefined = undefined;

  // Images & Data
  let referenceImage: [string, FabricObject] | undefined = undefined;
  let drawingData: [string, string] | undefined = undefined;

  referenceCanvas.backgroundColor = 'white';
  referenceCanvas.renderAll();
  drawingCanvas.backgroundColor = 'rgba(255, 255, 255, 0.5)';
  drawingCanvas.renderAll();
  updateRoot();
  updateDrawing(showReference, showDrawing);
  setupKeyboardControls();
  setupInteractiveControls();

  const r = setReferenceImage(reference);
  const d = setDrawingData(drawing);

  /*
  const furnitures = [
    addFurniture('ico-const-door-left', false),
    addFurniture('ico-const-door-right', false),
    addFurniture('ico-const-wall', true),
    addFurniture('ico-const-wall', true),
    addFurniture('ico-const-wall', true),
    addFurniture('ico-const-wall', true),
    addFurniture('ico-const-wall', true),
    addFurniture('ico-const-wall', true),
    addFurniture('ico-const-window', true),
    addFurniture('ico-const-window', true),
  ];
  Promise.all(furnitures)
    .then(() => drawingCanvas.renderAll()) */

  window.PLFloorPlanEditorUpdate = function (id, reference, drawing, isDisabled, showReference, showDrawing) {
    console.log('Floor Plan Editor update, reference=' + reference + ', drawing=' + drawing + ', isDisabled=' + isDisabled + ', showReference=' + showReference + ', showDrawing=' + showDrawing);
    const r = setReferenceImage(reference);
    const d = setDrawingData(drawing);
    updateDrawing(showReference, showDrawing);
  };

  window.PLFloorPlanEditorTogglePanning = function () {
    if (isPanning) {
      stopPanning();
    } else {
      startPanning();
    }
  }

  window.PLFloorPlanEditorZoom = function (delta) {
    zoomLevel *= 0.999 ** delta;
    if (zoomLevel > 20) zoomLevel = 20;
    if (zoomLevel < 0.01) zoomLevel = 0.01;
    updateRoot();
  };

  window.PLFloorPlanEditorZoomReset = function () {
    zoomLevel = zoomInitial;
    updateRoot();
  };

  window.PLFloorPlanEditorSave = function () {
    const jsonData = drawingCanvas.toJSON();
    const jsonString = JSON.stringify(jsonData);

    const formData = new FormData();
    const jsonBlob = new Blob([jsonString], { type: 'application/json' });
    formData.append('file', jsonBlob, 'canvas.json');
    formData.append('type', 'fabric');
    formData.append('project', project);

    fetch('/floor-plan-editor-upload', {
      method: 'POST',
      body: formData
    }).then(response => {
      if (response.ok) {
        window.htmx.trigger(`#floor-plan-editor-refresh-${id}`, 'click');
      } else {
        console.error('Failed to upload the canvas JSON:', response.statusText);
      }
    }).catch(error => {
      console.error('Error during fetch:', error);
    });
  };

  async function setReferenceImage(image: [string, string] | undefined) {
    const [id1] = referenceImage ?? [];
    const [id2] = image ?? [];
    if (id1 !== id2) {
      if (referenceImage) {
        referenceCanvas.remove(referenceImage[1]);
      }
      if (image && image[1] && image[1] !== '') {
        const img = await FabricImage.fromURL(image[1], {'crossOrigin': 'anonymous'});
        referenceImage = [image[0], img];
        img.scaleToWidth(referenceCanvas.width);
        img.scaleToHeight(referenceCanvas.height);
        referenceCanvas.add(img);
      }
    } else {
      referenceImage = undefined
    }
  }

  async function loadJSONFromURL(url: string): Promise<any> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    return response.json();
  }

  async function setDrawingData(data: [string, string] | undefined) {
    const [id1] = drawingData ?? [];
    const [id2] = data ?? [];
    if (id1 !== id2) {
      if (data && data[1] && data[1] !== '') {
        const jsonData = await loadJSONFromURL(data[1]);
        await referenceCanvas.loadFromJSON(jsonData, referenceCanvas.renderAll.bind(referenceCanvas));
        drawingData = data;
      }
    } else {
      drawingData = undefined
    }
    addFurniture('ico-const-door-left', false).then();
  }

  async function addFurniture(name: string, fixed: boolean) {
    const img = await FabricImage.fromURL('/assets/img/' + name + '.svg', {'crossOrigin': 'anonymous'});
    img.set({
      left: 110,
      top: 60,
      hasControls: true,
      hasBorders: true
    });
    if (fixed) {
      img.lockScalingY = true;
      img.setControlsVisibility({
        mt: false, // Middle Top disable
        mb: false, // Middle Bottom disable
        ml: true, // Middle Left enable
        mr: true, // Middle Right enable
        bl: false, // Bottom Left disable
        br: false, // Bottom Right disable
        tl: false, // Top Left disable
        tr: false, // Top Right disable
        mtr: true, // Rotating point enable
      });
    }
    drawingCanvas.add(img);
  }

  function copyObject() {
    drawingCanvas.getActiveObject()?.clone()
      .then((obj) => copiedObject = obj)
  }

  function pasteObject() {
    copiedObject?.clone()
      .then((obj) => {
        drawingCanvas.discardActiveObject();
        obj.set({
          left: obj.left + 10,
          top: obj.top + 10,
        });
        if (obj instanceof ActiveSelection) {
          obj.canvas = drawingCanvas;
          obj.forEachObject(function (obj) {
            drawingCanvas.add(obj);
          });
          obj.setCoords();
        } else {
          drawingCanvas.add(obj);
        }
        drawingCanvas.setActiveObject(obj);
        drawingCanvas.requestRenderAll();
      })
  }

  function setupKeyboardControls() {
    // Copy and Paste
    container?.addEventListener('keydown', function (e) {
      if (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) { // Clone on ctrl+c
        copyObject();
        swallow(e);
      } else if (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) { // Paste on ctrl+v
        pasteObject();
        swallow(e);
      } else if (e.code === 'KeyD' && (e.ctrlKey || e.metaKey)) { // Duplicate on ctrl+d
        copyObject();
        pasteObject();
        swallow(e);
      }
    });

    // Keyboard movement
    container?.addEventListener('keydown', function (e) {
      // set the step size for each arrow key press
      let step = 1;
      if (e.shiftKey) { // if shift key is down then increase step
        step = 20;
      }

      // get the active object - the one you want to move
      const activeObject = drawingCanvas.getActiveObject();

      if (!activeObject) return; // if no active object, do nothing

      switch (e.code) {
        case 'ArrowLeft': // left arrow key
          activeObject.left -= step;
          break;
        case 'ArrowUp': // up arrow key
          activeObject.top -= step;
          break;
        case 'ArrowRight': // right arrow key
          activeObject.left += step;
          break;
        case 'ArrowDown': // down arrow key
          activeObject.top += step;
          break;
      }

      activeObject.setCoords(); // necessary to keep track of object after moving it
      drawingCanvas.renderAll(); // re-render the canvas to see the result
    });
  }

  function setupInteractiveControls() {
    // Rotation snapping with shift
    drawingCanvas.on('object:rotating', function (opt) {
      if (opt.e.shiftKey === true) { // if shift key is down then snap rotation
        opt.target.snapAngle = 45;
      } else {
        opt.target.snapAngle = undefined;
      }
    });

    // Zooming
    container?.addEventListener('wheel', function (e) {
      if (e.ctrlKey || e.metaKey) {
        window.PLFloorPlanEditorZoom(e.deltaY);
      }
    });

    // Panning
    container?.addEventListener('keydown', function (e) {
      if (e.code === 'Space') {
        startPanning();
        swallow(e);
      }
    });
    container?.addEventListener('keyup', function (e) {
      if (e.code === 'Space') {
        stopPanning();
        swallow(e);
      }
    });
    if (container) {
      container.onmousedown = function (e) {
        if (isPanning) {
          isMouseDown = true;
          previousX = e.clientX;
          previousY = e.clientY;
          swallow(e);
        }
      };
      container.onmouseup = function () {
        isMouseDown = false;
      };
      container.onmousemove = function (e) {
        if (isPanning && isMouseDown) {
          moveX += (e.clientX - previousX) / zoomLevel;
          moveY += (e.clientY - previousY) / zoomLevel;
          previousX = e.clientX;
          previousY = e.clientY;
          updateRoot();
          swallow(e);
        }
      };
    }
  }

  function startPanning() {
    isPanning = true;
    drawingCanvas.selection = false;
    drawingCanvas.isDrawingMode = false;
    drawingCanvas.defaultCursor = 'grab';
    zoomPanButton?.classList.add('active');
    zoomPanButton?.setAttribute('aria-pressed', 'true');
    if (container) {
      container.style.cursor = 'grab';
    }
  }

  function stopPanning() {
    isPanning = false;
    drawingCanvas.selection = true;
    drawingCanvas.defaultCursor = 'default';
    zoomPanButton?.classList.remove('active');
    zoomPanButton?.setAttribute('aria-pressed', 'false');
    if (container) {
      container.style.cursor = 'default';
    }
  }

  function updateDrawing(showReference: boolean, showDrawing: boolean) {
    if (referenceRoot) {
      referenceRoot.style.display = showReference ? 'block' : 'none';
    }
    if (drawingRoot) {
      drawingRoot.style.display = showDrawing ? 'block' : 'none';
    }
  }

  function updateRoot() {
    if (root) {
      root.style.transform = 'scale(' + zoomLevel + ')' + 'translate(' + moveX + 'px,' + moveY + 'px)';
    }
  }

  function swallow(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
};

console.log('PLFloorPlanEditor2 has been installed');
