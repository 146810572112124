/// <reference path ="./custom-window.d.ts" />

window.HXDisableTarget = function (id, target, separator) {
	console.log('Disable Target, id=' + id + ', target=' + target);

	const element = document.getElementById(id)
	if (element !== null) {
		element.addEventListener('htmx:configRequest', function (evt) {
			const targetElement = document.getElementById(target)
			if (targetElement) {
				targetElement.style.pointerEvents = "none";
				document.addEventListener('htmx:afterSwap', handleAfterSwap);
			}
		});
	}

	function handleAfterSwap() {
		const targetElement = document.getElementById(target)
		if (targetElement) {
			targetElement.style.pointerEvents = "auto";
		}
		document.removeEventListener('3', handleAfterSwap);
	};
}

console.log("PLButton has been installed");
