import 'bootstrap/scss/bootstrap.scss'
import * as bootstrap from 'bootstrap'

import htmx from "htmx.org/dist/htmx.esm";

window.htmx = htmx;

import './PLGallery.js'
import './PLFloorPlanEditor.ts'
import './PLImageEditor.ts'
import './PLListGroup.js'
import './PLForm.ts'
import './PLInput.ts'
import './PLMap.js'
import './PLModal.ts'
import './PLMovie.ts'
import './PLPageControl.js'
import './PLSelectInput.ts'
import './PLTable.js'
import './PLTextEditor.ts'
import './PLToast.ts'
import './HXDisableTarget.ts'

window.PLGlobalsInstall = function () {
  htmx.defineExtension('proplab-use-search', {
    onEvent: function (name, evt) {
      if (name === "htmx:configRequest") {
        let search = evt.detail.parameters['search'];
        delete evt.detail.parameters['search'];
        let url = new URL(evt.detail.path, window.location.href);
        url.searchParams.set("search", search);
        evt.detail.path = url.toString();
      }
    }
  });

  window.PLModalInit();
}

window.PLGlobalsConfirm = function (target, event, dialogTitle, dialogText, dialogPositiveBtn, dialogNegativeBtn) {
  const dialog = document.getElementById("pl-globals-open-dialog")
  const closeDialog = document.getElementById("close-dialog")
  const closeDialog2 = document.getElementById("close-dialog-2")
  const closeDialog2Text = document.getElementById("close-dialog-2-text")
  const confirmRemove = document.getElementById("pl-globals-confirm-remove")
  const dialogTitleId = document.getElementById("confirmDialogTitleId")
  const dialogTextId = document.getElementById("confirmDialogTextId")

  dialog.showModal()
  dialogTitleId.innerText = dialogTitle
  dialogTextId.innerText = dialogText
  confirmRemove.innerText = dialogPositiveBtn
  closeDialog2Text.innerText = dialogNegativeBtn

  confirmRemove.addEventListener("click", () => {
    const callback = document.getElementById(target)
    if (callback)
      htmx.trigger(callback, event)
    dialog.close()
  })

  closeDialog.addEventListener("click", () => {
    dialog.close()
  })

  closeDialog2.addEventListener("click", () => {
    dialog.close()
  })
}

window.PLGlobalsPrint = function (id) {
  const originalContent = document.getElementById(id).innerHTML;
  const originalHead = document.head.innerHTML;
  const printWindow = window.open("", "_blank", "width=800,height=600");

  printWindow.document.write(`
    <html>
      <head>
        ${originalHead}
      </head>
      <body>
        ${originalContent}
      </body>
    </html>
  `);

  printWindow.document.close();
  printWindow.onload = function () {
    function triggerPrint() {
      printWindow.focus();
      printWindow.onafterprint = function () {
        printWindow.close();
      };
      printWindow.print();
    }
/*
    const map = printWindow.document.getElementById('map--map');
    if (map && typeof google !== 'undefined') {
      google.maps.event.addListenerOnce(mapInstance, 'idle', function () {
        triggerPrint();
      });
    } else {
      triggerPrint();
    }*/

    setTimeout(function () {
      triggerPrint();
    }, 1000);
  };
}

console.log('PLGlobals has been installed');

