import {Canvas, TEvent, Circle, PencilBrush} from 'fabric';

export class MaskBrush extends PencilBrush {
  private _container: HTMLElement
  private _customCursor: Circle;

  constructor(canvas: Canvas, container: HTMLElement) {
    super(canvas);
    this._container = container

    // Create custom cursor
    this._customCursor = this._createCursor();
    canvas.add(this._customCursor);
    this._bindCursorEvents(); // Bind event listeners for mouse movement
  }

  private _createCursor(): Circle {
    return new Circle({
      radius: this.width / 2,
      fill: 'rgba(0, 255, 0, 0.2)',
      originX: 'center',
      originY: 'center',
      selectable: false, // Make sure the custom cursor is not selectable
      evented: false, // Disable event handling on the custom cursor
      visible: false, // Initially hidden until mouse is over the canvas
    });
  }

  // Bind custom cursor events
  private _bindCursorEvents() {
    this.canvas.on('mouse:move', (opts) => this._updateCursor(opts));
    this.canvas.on('mouse:out', () => this._hideCursor());
    this.canvas.on('mouse:over', () => this._showCursor());
  }

  // Unbind custom cursor events
  private _unbindCursorEvents() {
    this.canvas.off('mouse:move', this._updateCursor);
    this.canvas.off('mouse:out', this._hideCursor);
    this.canvas.off('mouse:over', this._showCursor);
  }

  private _updateCursor(opts: TEvent) {
    const pointer = this.canvas.getViewportPoint(opts.e);
    this._customCursor.set({
      left: pointer.x,
      top: pointer.y,
      radius: this.width / 2 // Bind the cursor radius to the brush width
    });
    this._customCursor.setCoords();
    this.canvas.requestRenderAll();
  }

  private _hideCursor() {
    this._customCursor.set({visible: false});
    this._container.style.cursor = ''; // Revert to default cursor
    this.canvas.requestRenderAll();
  }

  private _showCursor() {
    this._customCursor.set({visible: true});
    this._container.style.cursor = 'none'; // Hide default cursor
    this.canvas.requestRenderAll();
  }

  // Method to clean up the brush
  cleanUp() {
    this._hideCursor();
    this._unbindCursorEvents();
    this.canvas.remove(this._customCursor);
    this.canvas.requestRenderAll();
  }
}
