import {Canvas, Circle, FabricObject, Rect, util} from "fabric";
import {EraserBrush} from "./EraserBrush";
import {MaskBrush} from "./MaskBrush";

export function scaleObject(obj: FabricObject, scale: [x: number, y: number]) {
  const currentScaleX = obj.scaleX;
  const currentScaleY = obj.scaleY;
  const currentLeft = obj.left;
  const currentTop = obj.top;
  obj.scaleX = currentScaleX * scale[0];
  obj.scaleY = currentScaleY * scale[1];
  obj.left = currentLeft * scale[0];
  obj.top = currentTop * scale[1];
  obj.setCoords();
}

export function removeBrushes(canvas: Canvas) {
  if (canvas.freeDrawingBrush instanceof EraserBrush) {
    canvas.freeDrawingBrush.cleanUp();
  }
  if (canvas.freeDrawingBrush instanceof MaskBrush) {
    canvas.freeDrawingBrush.cleanUp();
  }
  canvas.freeDrawingBrush = undefined;
}

export function swallow(event: Event) {
  event.preventDefault();
  event.stopPropagation();
}

export function createLoadingCircle(canvas: Canvas): FabricObject[] {
  const overlay = new Rect({
    left: 0,
    top: 0,
    width: canvas.getWidth(),
    height: canvas.getHeight(),
    fill: 'rgba(255, 255, 255, 0.5)',
    selectable: false,
    evented: false,
  });
  canvas.add(overlay);

  const loadingCircle = new Circle({
    radius: 30,
    startAngle: 0,
    endAngle: 60,
    fill: 'rgba(0, 0, 0, 0)',
    stroke: '#3498db',
    strokeWidth: 5,
    originX: 'center',
    originY: 'center',
    left: canvas.getWidth() / 2,
    top: canvas.getHeight() / 2,
  });

  canvas.add(loadingCircle);

  function animateLoadingCircle() {
    if (loadingCircle) {
      loadingCircle.animate(
        {angle: loadingCircle.angle + 360},
        {
          onChange: canvas.renderAll.bind(canvas),
          duration: 1000,
          easing: util.ease.easeInOutCubic,
          onComplete: animateLoadingCircle,
        }
      );
    }
  }

  animateLoadingCircle();

  return [overlay, loadingCircle];
}
